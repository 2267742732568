var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    _vm._b(
      {
        attrs: {
          "form-action-method": _vm.formActionWindow,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          "is-form-submitting": _vm.formSubmitting,
          "page-path": _vm.pagePath,
          title: _vm.title,
          "title-icon": "mdi-file-table",
          "initial-width": "4",
        },
      },
      "asyent-window",
      _vm.$attrs,
      false
    ),
    [
      _c("asyent-form", {
        ref: _vm.formRefAE,
        attrs: {
          "view-mode": _vm.viewMode,
          "data-error": _vm.dataError,
          "data-loading": _vm.dataLoading,
          errors: _vm.errors,
          "form-action-method": _vm.formActionMethod,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          "is-form-submitting": _vm.formSubmitting,
          readonly: _vm.isReadonly,
          reference: _vm.formRef,
          success: _vm.success,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { title: "General", expandable: "" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("asyent-form-display", {
                                              attrs: {
                                                value: _vm.formDataF["id"],
                                                label: "Consignment ID",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("asyent-form-display", {
                                              attrs: {
                                                value: [
                                                  _vm.formDataF["originatorId"],
                                                  _vm.formDataF[
                                                    "originatorName"
                                                  ],
                                                ],
                                                label: "Originator",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("asyent-form-display", {
                                              attrs: {
                                                value:
                                                  _vm.formDataF["providerName"],
                                                label: "Provider",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("asyent-form-display", {
                                              attrs: {
                                                value:
                                                  _vm.formDataF[
                                                    "providerObjectId"
                                                  ],
                                                label:
                                                  "Provider Consignment ID",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("asyent-form-display", {
                                              attrs: {
                                                value:
                                                  _vm.formDataF[
                                                    "carrierBookingNumber"
                                                  ],
                                                label: "Carrier Booking Number",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("asyent-form-display", {
                                              attrs: {
                                                value: [
                                                  _vm.formDataF["loadingPlace"][
                                                    "code"
                                                  ],
                                                  _vm.formDataF["loadingPlace"][
                                                    "name"
                                                  ],
                                                  _vm.formDataF["loadingPlace"][
                                                    "countryName"
                                                  ],
                                                ],
                                                label: "Loading Place",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("asyent-form-display", {
                                              attrs: {
                                                value: [
                                                  _vm.formDataF[
                                                    "dischargePlace"
                                                  ]["code"],
                                                  _vm.formDataF[
                                                    "dischargePlace"
                                                  ]["name"],
                                                  _vm.formDataF[
                                                    "dischargePlace"
                                                  ]["countryName"],
                                                ],
                                                label: "Discharge Place",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-chip",
                                              { attrs: { color: "success" } },
                                              [_vm._v("NO DANGEROUS GOODS")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "12" } },
                                          [
                                            _c(
                                              "asyent-card",
                                              {
                                                attrs: {
                                                  title: "Voyage",
                                                  flat: "",
                                                  "border-color": 2,
                                                  "with-border": "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value: [
                                                                        _vm
                                                                          .formDataF[
                                                                          "idCargoVoyage"
                                                                        ][
                                                                          "departurePort"
                                                                        ][
                                                                          "code"
                                                                        ],
                                                                        _vm
                                                                          .formDataF[
                                                                          "idCargoVoyage"
                                                                        ][
                                                                          "departurePort"
                                                                        ][
                                                                          "name"
                                                                        ],
                                                                        _vm
                                                                          .formDataF[
                                                                          "idCargoVoyage"
                                                                        ][
                                                                          "departurePort"
                                                                        ][
                                                                          "countryName"
                                                                        ],
                                                                      ],
                                                                      label:
                                                                        "Departure Port",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "idCargoVoyage"
                                                                        ][
                                                                          "voyageNumber"
                                                                        ],
                                                                      label:
                                                                        "Voyage Number",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "idCargoVoyage"
                                                                        ][
                                                                          "departureDate"
                                                                        ],
                                                                      "as-date":
                                                                        "",
                                                                      label:
                                                                        "Departure Date",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value: [
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportMeans"
                                                                        ][
                                                                          "idNumber"
                                                                        ],
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportMeans"
                                                                        ][
                                                                          "name"
                                                                        ],
                                                                      ],
                                                                      label:
                                                                        "Transport Means",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "12" } },
                                          [
                                            _c(
                                              "asyent-card",
                                              {
                                                attrs: {
                                                  title: "Transport Document",
                                                  flat: "",
                                                  "border-color": 2,
                                                  "with-border": "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "transportDocumentType"
                                                                        ],
                                                                      label:
                                                                        "Document Type",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "transportDocumentReference"
                                                                        ],
                                                                      label:
                                                                        "Reference",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "contractCarriageCondition"
                                                                        ],
                                                                      label:
                                                                        "Contract Carriage Condition",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value: [
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "acceptanceDate"
                                                                        ],
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "acceptancePlace"
                                                                        ],
                                                                      ],
                                                                      label:
                                                                        "Acceptance Date",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "issueDate"
                                                                        ],
                                                                      label:
                                                                        "Issue Date",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-checkbox",
                                                                  {
                                                                    staticClass:
                                                                      "pa-0 ma-0",
                                                                    attrs: {
                                                                      disabled: true,
                                                                      label:
                                                                        "Negotiable",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "negotiable"
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .formDataF[
                                                                              "transportDocument"
                                                                            ],
                                                                            "negotiable",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "formDataF['transportDocument']['negotiable']",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-checkbox",
                                                                  {
                                                                    staticClass:
                                                                      "pa-0 ma-0",
                                                                    attrs: {
                                                                      disabled: true,
                                                                      label:
                                                                        "eBL",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "eBL"
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .formDataF[
                                                                              "transportDocument"
                                                                            ],
                                                                            "eBL",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "formDataF['transportDocument']['eBL']",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-checkbox",
                                                                  {
                                                                    staticClass:
                                                                      "pa-0 ma-0",
                                                                    attrs: {
                                                                      disabled: true,
                                                                      label:
                                                                        "Part Bill",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "partBill"
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .formDataF[
                                                                              "transportDocument"
                                                                            ],
                                                                            "partBill",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "formDataF['transportDocument']['partBill']",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              extended: "",
                              expandable: "",
                              title: "Risk Assessment Result",
                            },
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          title: "Pre-Loading Selectivity",
                                          flat: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("asyent-form-display", {
                                                  staticClass: "pa-2",
                                                  style: {
                                                    "background-color":
                                                      _vm.showRiskAsColor(
                                                        "plpSystemRiskLevel"
                                                      ),
                                                  },
                                                  attrs: {
                                                    value:
                                                      _vm.showRisk(
                                                        "plpSystemRiskLevel"
                                                      ),
                                                    label: "System Risk",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("asyent-form-display", {
                                                  staticClass: "pa-2",
                                                  style: {
                                                    "background-color":
                                                      _vm.showRiskAsColor(
                                                        "plpOfficerRiskLevel"
                                                      ),
                                                  },
                                                  attrs: {
                                                    value: _vm.showRisk(
                                                      "plpOfficerRiskLevel"
                                                    ),
                                                    label: "Officer Decision",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          title: "Pre-Arrival Selectivity",
                                          flat: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("asyent-form-display", {
                                                  staticClass: "pa-2",
                                                  style: {
                                                    "background-color":
                                                      _vm.showRiskAsColor(
                                                        "papSystemRiskLevel"
                                                      ),
                                                  },
                                                  attrs: {
                                                    value:
                                                      _vm.showRisk(
                                                        "papSystemRiskLevel"
                                                      ),
                                                    label: "System Risk",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("asyent-form-display", {
                                                  staticClass: "pa-2",
                                                  style: {
                                                    "background-color":
                                                      _vm.showRiskAsColor(
                                                        "papOfficerRiskLevel"
                                                      ),
                                                  },
                                                  attrs: {
                                                    value: _vm.showRisk(
                                                      "papOfficerRiskLevel"
                                                    ),
                                                    label: "Officer Decision",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          title: "Post-Arrival Selectivity",
                                          flat: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("asyent-form-display", {
                                                  staticClass: "pa-2",
                                                  style: {
                                                    "background-color":
                                                      _vm.showRiskAsColor(
                                                        "postSystemRiskLevel"
                                                      ),
                                                  },
                                                  attrs: {
                                                    value: _vm.showRisk(
                                                      "postSystemRiskLevel"
                                                    ),
                                                    label: "System Risk",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("asyent-form-display", {
                                                  staticClass: "pa-2",
                                                  style: {
                                                    "background-color":
                                                      _vm.showRiskAsColor(
                                                        "postOfficerRiskLevel"
                                                      ),
                                                  },
                                                  attrs: {
                                                    value: _vm.showRisk(
                                                      "postOfficerRiskLevel"
                                                    ),
                                                    label: "Officer Decision",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "v-card",
                          { attrs: { color: "bgWhite" } },
                          [
                            _c(
                              "v-toolbar",
                              {
                                attrs: {
                                  color: "primary",
                                  dark: "",
                                  "max-height": "56",
                                },
                              },
                              [
                                _c("v-toolbar-title", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formDataF["consignmentControl"][
                                          "controlName"
                                        ]
                                      ) +
                                      " - Control Report "
                                  ),
                                ]),
                                _c("v-spacer"),
                              ],
                              1
                            ),
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "4" } },
                                      [
                                        _c("asyent-form-select", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            "items-reload":
                                              _vm.controlDecisionReload,
                                            props: _vm.props,
                                            "drop-down": "",
                                            "item-text": "description",
                                            "item-value": "code",
                                            label: "Control Decision",
                                            mandatory: "",
                                            name: "controlDecision",
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF[
                                                "consignmentControl"
                                              ]["controlDecision"],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF[
                                                  "consignmentControl"
                                                ],
                                                "controlDecision",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['consignmentControl']['controlDecision']",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "4" } },
                                      [
                                        _c("asyent-form-select", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            "items-reload":
                                              _vm.controlDecisionReasonReload,
                                            props: _vm.props,
                                            "drop-down": "",
                                            "item-text": "description",
                                            "item-value": "code",
                                            label: "Control Decision Reason",
                                            mandatory: "",
                                            name: "controlDecisionReason",
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF[
                                                "consignmentControl"
                                              ]["controlDecisionReason"],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF[
                                                  "consignmentControl"
                                                ],
                                                "controlDecisionReason",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['consignmentControl']['controlDecisionReason']",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "4" } },
                                      [
                                        _c("asyent-form-select", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            "items-reload":
                                              _vm.controlResultReload,
                                            props: _vm.props,
                                            "drop-down": "",
                                            "item-text": "description",
                                            "item-value": "code",
                                            label: "Control Result",
                                            mandatory: "",
                                            name: "controlResult",
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF[
                                                "consignmentControl"
                                              ]["controlResult"],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF[
                                                  "consignmentControl"
                                                ],
                                                "controlResult",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['consignmentControl']['controlResult']",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          md: "12",
                                          lg: "12",
                                        },
                                      },
                                      [
                                        _c("asyent-form-textarea", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: _vm.props,
                                            flat: "",
                                            label: "Additional Information",
                                            name: "controlAddInfo",
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF[
                                                "consignmentControl"
                                              ]["controlAddInfo"],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF[
                                                  "consignmentControl"
                                                ],
                                                "controlAddInfo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['consignmentControl']['controlAddInfo']",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("asyent-toc"),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.formValid,
          callback: function ($$v) {
            _vm.formValid = $$v
          },
          expression: "formValid",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }